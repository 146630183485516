.btn {
    @apply inline-flex items-center px-3 py-2 text-sm font-medium leading-4 transition duration-150 ease-in-out border border-transparent rounded-md no-underline;

    &:disabled {
        @apply opacity-50 cursor-not-allowed;
    }

    &.btn-is-loading,
    &.btn-is-loading:disabled {
        @apply cursor-wait;
    }

    &.btn-xs {
        @apply px-2.5 py-1.5 text-xs leading-4 font-medium rounded-md;
    }

    &.btn-sm {
        @apply px-3 py-2 text-sm font-medium leading-4 rounded-md;
    }

    &.btn-md {
        @apply px-4 py-2 text-sm font-medium leading-5 rounded-md;
    }

    &.btn-lg {
        @apply px-4 py-2 text-base font-medium leading-6 rounded-md;
    }

    &.btn-xl {
        @apply px-6 py-3 text-base font-medium leading-6 rounded-md;
    }

    &.btn-default {
        @apply bg-white border-gray-300;
        &:hover {
            @apply text-gray-500;
        }
        &:focus {
            @apply border-blue-300 outline-none shadow-outline-blue;
        }
        &:active {
            @apply text-gray-800 bg-gray-50;
        }
    }

    &.btn-primary {
        @apply text-white bg-sky-500;
        &:hover:not(:disabled) {
            @apply bg-sky-400;
        }
        &:focus {
            @apply outline-none border-sky-600 shadow-outline-blue;
        }
        &:active {
            @apply bg-sky-600;
        }
    }

    &.btn-success {
        @apply text-white bg-green-500;
        &:hover:not(:disabled) {
            @apply bg-green-400;
        }
        &:focus {
            @apply outline-none border-green-600 shadow-outline-green;
        }
        &:active {
            @apply bg-green-600;
        }
    }

    &.btn-magic {
        @apply text-white bg-indigo-500 border-indigo-600 font-semibold;
        &:hover:not(:disabled) {
            @apply bg-indigo-500;
        }
        &:focus {
            @apply outline-none border-indigo-600 shadow-outline-indigo;
        }
        &:active {
            @apply bg-indigo-600;
        }
    }

    &.btn-secondary {
        @apply text-blue-500 bg-blue-100;
        &:hover:not(:disabled) {
            @apply bg-blue-50;
        }
        &:focus {
            @apply border-blue-300 outline-none shadow-outline-blue;
        }
        &:active {
            @apply bg-blue-200;
        }
    }

    &.btn-danger {
        @apply text-white bg-red-600;
        &:hover:not(:disabled) {
            @apply bg-red-500;
        }
        &:focus {
            @apply border-red-700 outline-none shadow-outline-red;
        }
        &:active {
            @apply bg-red-700;
        }
    }

    &.btn-warning {
        @apply text-white bg-orange-500;
        &:hover:not(:disabled) {
            @apply bg-orange-400;
        }
        &:focus {
            @apply border-orange-600 outline-none shadow-outline-orange;
        }
        &:active {
            @apply bg-orange-600;
        }
    }

    &.btn-dark {
        @apply text-gray-300 bg-gray-800 border-gray-900 shadow;
        &:hover:not(:disabled) {
            @apply text-gray-200 bg-gray-700;
        }
        &:focus {
            @apply outline-none;
        }
        &:active {
            @apply text-gray-300 bg-gray-700;
        }
    }

    &.btn-is-loading {
        & .btn-loading-icon-wrapper {
            & > svg {
                animation: fa-spin 1s infinite linear;
            }
        }
    }

    & .inset-input-right {
        height: 2rem;
        margin-top: .3rem;
        margin-right: .5rem;
    }
}

.btn-wrapper {
    &.btn-block {
        @apply block w-full;

        & > .btn {
            @apply relative justify-center w-full text-center;

            & .btn-icon-wrapper {
                @apply absolute inset-y-0 left-0 flex items-center pl-3;
            }
        }
    }
}

/** night mode mods **/
body[data-theme='dark'] {
    & .btn {
        &.btn-default {
            @apply text-gray-300 bg-gray-800 border-gray-900 shadow;
            &:hover:not(:disabled) {
                @apply text-gray-200 bg-gray-700;
            }
            &:focus {
                @apply outline-none;
            }
            &:active {
                @apply text-gray-300 bg-gray-700;
            }
        }

        &.btn-primary {
            @apply border-gray-900 shadow;
        }

        &.btn-magic {
            @apply text-gray-900 shadow;
        }

        &.btn-secondary {
             @apply border-gray-900 shadow;
        }
    }
}