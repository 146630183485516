html,
body {
    @apply bg-gray-900;
    overscroll-behavior: none;
}

a {
    @apply text-blue-500 underline;
}

#app-spacer {
    @apply my-12;
}

/** mobile **/
@media (min-width: 200px) and (max-width: 480px) {
    html, body {
        @apply bg-gray-900 text-gray-100;
    }

    #app-panel {
        @apply rounded-none shadow-none;
    }

    #app-spacer {
        @apply py-4 my-4;
    }

    #copyright {
        @apply px-4 text-gray-100;
    }
}

.form-input {
    @apply w-full p-3 leading-4 border border-indigo-500 rounded-md shadow;
}

progress::-webkit-progress-value {
  @apply bg-green-500;
}

progress::-moz-progress-bar {
  @apply bg-green-900;
}

progress::-webkit-progress-value {
  @apply bg-green-500;
}

progress::-webkit-progress-bar {
  @apply bg-green-900;
}

.file-download-container {
    min-height: 63px;
}

.ember-cli-notifications-notification__container .c-notification__icon {
    justify-content: center;
    align-items: center;
    display: flex;
}